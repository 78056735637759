<template>
    <div>
        <iframe class="mr-16" frameborder="0" src="https://itch.io/embed/2576462?border_width=5&amp;bg_color=222222&amp;fg_color=ff0000&amp;link_color=ff0000&amp;border_color=ffa500" width="216" height="175"><a href="https://nickhatboecker.itch.io/find-this-pixel">Find This Pixel Anomaly by nickhatboecker</a></iframe>
        <iframe class="mr-16" frameborder="0" src="https://itch.io/embed/2597841?border_width=5&amp;bg_color=424e66&amp;fg_color=ffffff&amp;link_color=fffc29&amp;border_color=ffa500" width="216" height="175"><a href="https://nickhatboecker.itch.io/groceries-please-proof-of-concept">Groceries, Please - Proof of Concept by nickhatboecker</a></iframe>
        <iframe frameborder="0" src="https://itch.io/embed/2595570?border_width=5&amp;bg_color=333333&amp;fg_color=ffffff&amp;link_color=ffdb71&amp;border_color=ffa500" width="216" height="175"><a href="https://nickhatboecker.itch.io/puppy-rescue-proof-of-concept">Puppy Rescue - Proof of Concept by nickhatboecker</a></iframe>
    </div>
</template>

<script>
export default {
    name: 'ItchGames',
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/variables';

.mr-16 {
    margin-right: $spacing-unit-16;
}
</style>
